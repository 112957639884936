import { combineReducers } from "redux";
import { Auth } from "./Auth";
import { User } from "./User";
import { Landlord } from "./Landlord";
import { Property } from "./Property";
import { Utility } from "./Utilities";
import { MediaHub } from "./MediaHub";

const reducers = combineReducers({
    Auth,
    User,
    Landlord,
    Property,
    Utility,
    MediaHub
})

export default reducers;