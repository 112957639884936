import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Slide } from "@material-ui/core";
import "./style.css"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function ConfirmDelete(props) {
    const { open, getDialogValue, valueToDelete } = props;

    const handleClose = (agree, closeDialog) => {
        getDialogValue(agree, closeDialog)
    };



    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleClose(false, true)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="dialog"
        >
            <DialogTitle id="alert-dialog-slide-title">Do you want to delete {valueToDelete?.name.toUpperCase()} ?</DialogTitle>
            <DialogActions>
                <Button onClick={() => handleClose(false, true)} variant="contained" color="secondary">
                    No
                </Button>
                <Button onClick={() => handleClose(true, true)} variant="contained" color="primary" >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}